<template lang="pug">
    div
        loader
</template>

<script>
    export default {

        async created(){

            let params = this.$route.params
            let query  = this.$route.query
            
            try{

                if(!query.hasOwnProperty('state') || !query.hasOwnProperty('code')){
                    throw false
                }

                // Autorizar Google
                if(params.server == 'google'){

                    let data = await new Promise((resolve) => {
                        this.$api(this, (xhr) => {
                            xhr.post('/contact/auth/google', this.$qs.stringify({
                                server: params.server,
                                code  : query.code,
                                state : query.state
                            })).then((r) => {
                                resolve(r.data)
                            })
                        })
                    })
                    
                    if(data.response){
                        window.location.href = data.data
                    }else{
                        throw false
                    }

                }else{
                    throw false
                }

            }catch(e){
                this.$router.push({path: '/home'})
            }
        }
    }

</script>